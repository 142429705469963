<template>
  <section class="aulas">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar aula..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div v-for="aula in aulasFiltradas" :key="aula._id" class="aula">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${aula.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ aula.nome }}</h3>
        <p>{{ limitarTexto(aula.descricao, 90) }}</p>
      </div>

      <div class="dados">
        <div class="dado">
          <Svgs nome="relogio" />
          <p>
            <span>{{ aula.duracao }}</span>
          </p>
          <p>minutos</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="migrar" @click="abrirModalEdicao('migrarAula', 'migrar', 'Migrar aula', aula._id)">
          <Svgs nome="migrar" />
          <h6 class="tooltip">Migrar aula</h6>
        </button>
        <button class="excluir" @click="abrirModalEdicao('excluirAula', 'excluir', 'Excluir aula', aula._id)">
          <Svgs nome="excluir" />
          <h6 class="tooltip">Excluir aula</h6>
        </button>
        <button class="editar" @click="abrirModalEdicao('editarAula', 'editar', 'Editar aula', aula._id)">
          <Svgs nome="editar" />
          <h6 class="tooltip">Editar aula</h6>
        </button>
        <button class="duplicar" @click="abrirModalEdicao('duplicarAula', 'duplicar', 'Duplicar aula', aula._id)">
          <Svgs nome="duplicar" />
          <h6 class="tooltip">Duplicar aula</h6>
        </button>
        <button class="entrar" @click="abrirModalAula(aula._id)">
          <p>Ver aula</p>
          <Svgs nome="play" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()
const storeModal = useStoreModal()

const router = useRouter()
const searchQuery = ref('')
const selectedFilter = ref('todos')

const aulasFiltradas = computed(() => {
  let aulas = storeAulas.aulas || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    aulas = aulas.filter((aula) => aula.nome.toLowerCase().includes(query) || aula.descricao.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      aulas = aulas.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      aulas = aulas.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      aulas = aulas.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      aulas = aulas.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return aulas
})

function abrirModalAula(idAula) {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  storeAulas.receberPorId(idArea, idCurso, idModulo, idAula)
  router.push(`/areas/${storeAreas.area?.hotlink}/aulas/visualizar`)
}

function limitarTexto(texto, limite) {
  if (!texto) return ''
  return texto.length > limite ? texto.substring(0, limite) + '...' : texto
}

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}
</script>

<style scoped>
section.aulas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 10px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.aula {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.aula:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.aula .capa {
  width: 100%;
  max-width: 110px;
}

.aula .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.aula .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.aula .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.aula .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.aula .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.aula .opcoes {
  display: flex;
  align-items: center;
}

.aula .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
  position: relative;
}

.aula .opcoes button.duplicar {
  margin: 0 10px;
}

.aula .opcoes button.editar {
  margin: 0 0 0 10px;
}

.aula .opcoes button.excluir {
  margin: 0 0 0 10px;
}

.aula .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.aula .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.aula .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.aula .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.aula .dados {
  display: flex;
  align-items: center;
}

.aula .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.aula .dados .dado:nth-child(1) {
  border-left: none;
}

.aula .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.aula .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.aula .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.aulas {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .aula {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .aula .capa {
    width: 100%;
    max-width: 120px;
  }

  .aula .nome {
    margin: 0;
    width: calc(100% - 120px);
  }

  .aula .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .aula .opcoes button {
    margin: 0;
  }

  .aula .opcoes button.duplicar {
    margin: 0 10px 0 0;
  }

  .aula .opcoes button.editar {
    margin: 0 10px;
  }

  .aula .dados {
    padding: 20px 0;
    width: 100%;
  }

  .aula .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .aula .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .aula .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
